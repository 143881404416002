import { createGlobalStyle } from "styled-components";

export const Logo1 = (props) => {
  let GlobalStyles = null;
  let r = (Math.random() + 1).toString(36).substring(7);
  if (props.color) {
    GlobalStyles = createGlobalStyle`
    #logo-${r} {
      fill: ${props.color};
    }`;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 531.37 113.18"
      {...props}
    >
      {GlobalStyles && <GlobalStyles />}
      <g id={"logo-" + r} data-name="Layer 2">
        <path d="M157.73,61a33.48,33.48,0,0,0,8,6.9,15.68,15.68,0,0,0,7.89,2.28,9,9,0,0,0,6-2,6.18,6.18,0,0,0,2.36-5,6.54,6.54,0,0,0-2-5.14c-1.36-1.19-4.42-2.44-9.2-3.72q-9.84-2.66-13.92-6.9t-4.08-11.66q0-9.63,6.43-15.67c4.28-4.05,9.86-6.06,16.67-6.06a36.82,36.82,0,0,1,10.51,1.48,34,34,0,0,1,9.41,4.52l-5.73,13.1a24.09,24.09,0,0,0-6.38-4,17,17,0,0,0-6.46-1.34,8.63,8.63,0,0,0-5.35,1.57,4.87,4.87,0,0,0-2,4,5,5,0,0,0,1.79,4c1.2,1,3.53,2,7,2.88l.83.23q11.16,3,14.69,6.63a16.3,16.3,0,0,1,3.62,5.92A22.46,22.46,0,0,1,199,60.78q0,10.66-7,17.12t-18.67,6.49A33.17,33.17,0,0,1,160.54,82a36.21,36.21,0,0,1-11-7.46Z" />
        <polygon points="209.16 82.6 209.16 15.64 227.23 15.64 227.23 46.07 248.4 15.64 269.66 15.64 244.94 48.29 271.81 82.6 249.04 82.6 227.23 52.56 227.23 82.6 209.16 82.6" />
        <polygon points="277.54 82.6 277.54 15.64 296 15.64 296 82.6 277.54 82.6" />
        <path d="M310.69,82.6v-67h13.95q15.58,0,22.05,1.47A31.62,31.62,0,0,1,358.05,22a28.73,28.73,0,0,1,9.66,11.36A36.56,36.56,0,0,1,371,49.17,36.44,36.44,0,0,1,367.71,65a28.55,28.55,0,0,1-9.66,11.37A31.4,31.4,0,0,1,347,81.16q-6.21,1.44-19.27,1.44h-17Zm18.07-15h3.06c7.16,0,12.4-1.46,15.71-4.42s4.93-7.6,4.93-14-1.64-11.09-4.93-14.08-8.55-4.48-15.71-4.48h-3.06Z" />
        <path d="M398.52,57.6h18.15l-6.82-22.1c-.2-.68-.5-1.75-.86-3.25s-.84-3.42-1.41-5.76c-.4,1.64-.79,3.21-1.18,4.71s-.77,2.92-1.16,4.3Zm-25.59,25,23.12-67h22.87l23.13,67H423.9l-3.39-12H394.58l-3.48,12Z" />
        <path d="M492.41,15.64l8.41,31.85c.39,1.42.77,2.91,1.09,4.48s.69,3.34,1.07,5.35c.45-2.39.84-4.34,1.15-5.89s.64-2.86.94-3.94l7.86-31.85h18.44l-18.93,67H495.52L487.24,54c-.31-1-.81-3-1.52-5.9-.29-1.31-.54-2.33-.73-3.06-.14.63-.35,1.54-.64,2.7-.7,3-1.23,5-1.59,6.26L474.61,82.6h-17l-18.9-67h18.45l7.68,32c.38,1.65.73,3.29,1.07,4.94s.7,3.34,1,5.07c.28-1.28.58-2.61.9-4s.8-3.41,1.45-6l8.41-32Z" />
        <g>
          <path
            className="logoaja"
            d="M12.34,21.89,36.15,4.7,42.65,0,39.79,20.06,24.09,31.39C7.7,43.22,20.73,68.59,38.79,58.76c.62-.34,11.83-8.39,22.83-16.31l32.17,7.67c2.39-1.72-41.41,29.86-42,30.26-24.06,14.94-55.3-15-51.42-39A29.79,29.79,0,0,1,12.34,21.89ZM66.56,13,34.34,36l19,4.52c.37-.27,22.52-16.17,22.9-16.4C94.58,12.42,116.36,37.27,99.54,53c-.78.72-66,47.87-66.91,48.55C46.34,116.21,60,117,77.84,104.1c.72-.52,37.21-26.9,37.87-27.45C140.53,56,119.35,11.43,91.93,7A33.92,33.92,0,0,0,66.56,13Z"
          />
        </g>
      </g>
    </svg>
  );
};

export const Logo2 = (props) => {
  let GlobalStyles = null;
  let r = (Math.random() + 1).toString(36).substring(7);
  if (props.color) {
    GlobalStyles = createGlobalStyle`
    #logo-${r} {
      fill: ${props.color};
    }`;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 891.44 497.25"
      {...props}
    >
      {GlobalStyles && <GlobalStyles />}
      <g id={"logo-" + r} data-name="Layer 2">
        <path d="M104.11,410.46c-5.47-5.65-16.91-10.78-34.3-15.49l-1.92-.54c-8-2.11-13.46-4.38-16.26-6.7a11.72,11.72,0,0,1-4.18-9.41,11.34,11.34,0,0,1,4.81-9.43c3.2-2.45,7.36-3.68,12.45-3.68a39.64,39.64,0,0,1,15.09,3.12,56.47,56.47,0,0,1,14.91,9.34l13.37-30.61a79.78,79.78,0,0,0-22-10.53,85.7,85.7,0,0,0-24.53-3.46c-15.91,0-28.93,4.69-38.9,14.13s-15,21.6-15,36.59q0,17.26,9.52,27.21c6.33,6.6,17.2,12,32.49,16.12q16.73,4.49,21.47,8.69c3.17,2.82,4.74,6.82,4.74,12a14.43,14.43,0,0,1-5.5,11.62C66.72,462.5,62,464,56.34,464a36.56,36.56,0,0,1-18.41-5.32q-9.24-5.34-18.72-16.1L0,474.3a84.14,84.14,0,0,0,25.76,17.42,77.64,77.64,0,0,0,29.77,5.53q27.23,0,43.58-15.12c10.91-10,16.32-23.4,16.32-40a53,53,0,0,0-2.86-17.89A38.52,38.52,0,0,0,104.11,410.46Z" />
        <polygon points="280.51 336.79 230.87 336.79 181.47 407.79 181.47 336.79 139.27 336.79 139.27 493.1 181.47 493.1 181.47 422.95 232.37 493.1 285.54 493.1 222.78 413.01 280.51 336.79" />
        <rect x="298.91" y="336.78" width="43.07" height="156.32" />
        <path d="M486.85,351.6a73.87,73.87,0,0,0-26.51-11.39q-15.09-3.43-51.5-3.43H376.26V493.1H416c20.32,0,35.35-1.13,45-3.34a74.14,74.14,0,0,0,25.82-11.28,66.46,66.46,0,0,0,22.52-26.55q7.73-16.17,7.7-36.88t-7.7-36.91A66.84,66.84,0,0,0,486.85,351.6Zm-24.59,96.21c-7.68,6.89-19.93,10.32-36.66,10.32h-7.14V371.76h7.14c16.73,0,29,3.49,36.66,10.46s11.51,17.93,11.51,32.87S470,441,462.26,447.81Z" />
        <path d="M575.55,336.78l-54,156.32H564l8.12-28h60.52l7.9,28h42.39l-54-156.32Zm5.76,98L597,383.13c.91-3.2,1.81-6.56,2.69-10s1.82-7.18,2.73-11c1.35,5.47,2.45,9.94,3.33,13.46s1.52,6,2,7.57l15.93,51.6Z" />
        <path d="M848.4,336.78,830,411.12c-.7,2.55-1.42,5.6-2.18,9.22s-1.64,8.16-2.7,13.73c-.88-4.7-1.67-8.89-2.48-12.5s-1.64-7.14-2.55-10.45L800.5,336.78H766.13l-19.64,74.74q-2.24,9.08-3.4,14c-.72,3.27-1.41,6.39-2.07,9.4-.69-4.05-1.45-8-2.3-11.85s-1.64-7.7-2.51-11.55l-17.93-74.74H675.21L719.34,493.1h39.6l19-66.76c.85-2.83,2.08-7.68,3.74-14.61.64-2.69,1.16-4.8,1.48-6.3.44,1.71,1,4.09,1.72,7.14,1.64,6.85,2.8,11.44,3.53,13.77l19.31,66.76h39.5l44.2-156.32Z" />
        <path d="M313.63,56.66l61.64-44.49L392.13,0,384.7,51.92,344,81.27c-42.43,30.64-8.69,96.31,38.06,70.86,1.61-.88,30.66-21.74,59.13-42.21l83.3,19.85c6.18-4.46-107.22,77.3-108.87,78.33-62.3,38.7-143.18-38.79-133.13-101A77.15,77.15,0,0,1,313.63,56.66ZM454,33.66l-83.4,59.43,49.1,11.69c1-.69,58.32-41.86,59.3-42.48,47.56-30.15,104,34.2,60.39,74.94-2,1.88-170.82,123.94-173.25,125.72,35.52,37.92,70.93,39.86,117.06,6.56,1.85-1.33,96.36-69.66,98.05-71.06,64.27-53.54,9.43-168.9-61.54-180.37A88,88,0,0,0,454,33.66Z" />
      </g>
    </svg>
  );
};

export const LogoAja = (props) => {
  let GlobalStyles = null;
  let r = (Math.random() + 1).toString(36).substring(7);
  if (props.color) {
    GlobalStyles = createGlobalStyle`
    #logo-${r} {
      fill: ${props.color};
    }`;
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 328.08 293.05"
      {...props}
    >
      {GlobalStyles && <GlobalStyles />}
      <g id={"logo-" + r}>
        <path d="M32,56.66,93.59,12.17,110.45,0,103,51.92,62.36,81.27c-42.43,30.64-8.69,96.31,38.06,70.86,1.61-.88,30.65-21.74,59.13-42.21l83.3,19.85C249,125.31,135.63,207.07,134,208.1,71.68,246.8-9.21,169.31.85,107.06A77.15,77.15,0,0,1,32,56.66Zm140.38-23L88.93,93.09,138,104.78c1-.69,58.32-41.86,59.29-42.48,47.57-30.15,104,34.2,60.4,74.94C255.71,139.12,86.9,261.18,84.47,263c35.52,37.92,70.93,39.86,117.06,6.56,1.85-1.33,96.36-69.66,98-71.06C363.85,144.92,309,29.56,238,18.09A88,88,0,0,0,172.33,33.66Z" />
      </g>
    </svg>
  );
};
