import React from "react";
import { LogoAja } from "../../assets/logo";
import "./loading.scss";

class Loading extends React.Component {
  render() {
    return (
      <div className="loading">
        <LogoAja {...this.props} />
      </div>
    );
  }
}

export default Loading;
