import React from "react";
import "./app.scss";
import Content from "./components/main/content";
import ReactPixel from "react-facebook-pixel";

class App extends React.Component {
  componentDidMount() {
    ReactPixel.init("395142982604336");
    ReactPixel.pageView();
  }
  render() {
    return (
      <>
        <Content />
      </>
    );
  }
}

export default App;
