import React, { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Routes from "../../routes";
import LoadingFull from "./loadingfull";

const loading = <LoadingFull class="active" />;

const Layout = React.lazy(() => import("./layout"));
const BlankLayout = React.lazy(() => import("./blankLayout"));

class Content extends React.Component {
  renderWithLayout(Component, props, blank) {
    if (blank) return <Component {...props} />;
    else
      return (
        <Layout>
          <Component {...props} />
        </Layout>
      );
  }
  render() {
    return (
      <Router>
        <Suspense fallback={loading}>
          <BlankLayout>
            <Switch>
              {Routes.map((route, idx) => {
                return (
                  <Route
                    key={idx}
                    path={route.path}
                    exact={route.exact}
                    render={(props) =>
                      this.renderWithLayout(
                        route.component,
                        props,
                        !!route.blank
                      )
                    }
                  />
                );
              })}
            </Switch>
          </BlankLayout>
        </Suspense>
      </Router>
    );
  }
}

export default Content;
