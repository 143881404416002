import React from "react";

const Home = React.lazy(() => import("./pages/home/index"));
const DetailProduk = React.lazy(() => import("./pages/produk/wrapper"));
const KatalogProduk = React.lazy(() => import("./pages/produk/katalog"));
const CartPage = React.lazy(() => import("./pages/cart/cartPage"));
const ThanksPage = React.lazy(() => import("./pages/thanks/thankspage"));
// const TemplatingPage = React.lazy(() => import("./pages/landing-page/"));
const DashboardLayout = React.lazy(() =>
  import("./pages/dashboard/layout_dashboard")
);

// Landing Page
const raven1 = React.lazy(() =>
  import("./pages/landing-page/jaket-bisa-jadi-tas-serut-raven")
);
const raven2 = React.lazy(() =>
  import("./pages/landing-page/jaket-bisa-jadi-tas-serut-kekinian-raven")
);
const slingphonewallet = React.lazy(() =>
  import("./pages/landing-page/sling-phone-wallet-multifungsi")
);
const slingphonewallet2 = React.lazy(() =>
  import("./pages/landing-page/sling-wallet")
);
const gantika = React.lazy(() => import("./pages/landing-page/gantika"));

const skylight = React.lazy(() => import("./pages/landing-page/skylight"));

const dika = React.lazy(() => import("./pages/landing-page/dika"));
const satset = React.lazy(() => import("./pages/landing-page/satset"));

const Routes = [
  { path: "/", exact: true, component: Home },
  { path: "/thanks/:noorder", exact: true, component: ThanksPage, blank: true },
  { path: "/cart", exact: true, component: CartPage, blank: true },
  { path: "/detail/:slug", exact: true, component: DetailProduk },
  { path: "/shop", exact: true, component: KatalogProduk },
  { path: "/shop/:slug", exact: true, component: KatalogProduk },
  { path: "/dashboard", exact: false, component: DashboardLayout },
  {
    path: "/jaket-bisa-jadi-tas-serut-raven",
    exact: false,
    component: raven1,
    blank: true,
  },
  {
    path: "/jaket-bisa-jadi-tas-serut-kekinian-raven",
    exact: false,
    component: raven2,
    blank: true,
  },
  {
    path: "/sling-phone-wallet-multifungsi-deska",
    exact: false,
    component: slingphonewallet2,
    blank: true,
  },
  {
    path: "/sling-phone-wallet-multifungsi",
    exact: false,
    component: slingphonewallet,
    blank: true,
  },
  {
    path: "/parfum-penghilang-bau-apek-dan-bakteri",
    exact: false,
    component: gantika,
    blank: true,
  },
  {
    path: "/jaket-ringan-skylight",
    exact: false,
    component: skylight,
    blank: true,
  },
  {
    path: "/handbag-multifungsi-kekinian",
    exact: false,
    component: dika,
    blank: true,
  },
  {
    path: "/hoodie-sehangat-pelukan",
    exact: false,
    component: satset,
    blank: true,
  },
  // { path: "/:slug", exact: true, component: TemplatingPage, blank: true },
];

export default Routes;
