import React from "react";
import Loading from "./loading";
import "./loading-full.scss";

class LoadingFull extends React.Component {
  render() {
    const { show } = this.props;
    return (
      <div className={"loading-full " + (show ? "active" : "")}>
        <Loading className="m-auto" color="#fff" width="100px" />
      </div>
    );
  }
}

export default LoadingFull;
